body.no-scroll {
  margin: 0;
  position: absolute !important;
  overflow: hidden;
  width: 100%;
}

.s-modal {
  align-items: center;
  background-color: transparent;
  display: none;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: -1;
}

.s-modal.is-active {
  display: flex;
  background-color: rgba(8, 135, 212, 0.8);
  visibility: visible;
  z-index: 999;
  transition: background 0.35s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.s-modal.is-closing {
  background-color: transparent;
  transition: background 0.35s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.25s;
}

.s-modal__dialog {
  background: #fff;
  opacity: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  visibility: hidden;
  transform: translate(-50%, -50%) scale(0.688);
}

.s-modal__dialog.is-active {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
  transition: 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.32s;
}

.s-modal__dialog.is-closing {
  opacity: 0;
  transform: translate(-50%, -50%) scale(1.1);
  transition: 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.s-modal__dialog--sm {
  max-height: 90vh;
  max-width: 936px;
  overflow: auto;
  width: 88vw;
}

.s-modal__dialog--sm .s-modal__close {
  left: calc(100% - 12px);
  position: -webkit-sticky;
  position: sticky;
  top: 12px;
}

.s-modal__dialog--lg {
  height: calc(100% - 10px);
  width: calc(100% - 10px);
}

.s-modal__dialog--lg .s-modal__close {
  position: absolute;
  top: 0;
}

@media (min-width: 34em) {
  .s-modal__dialog--lg {
    height: 90vh;
    width: 96vw;
  }
}
@media (min-width: 75em) {
  .s-modal__dialog--lg {
    width: 90vw;
  }
}
.s-modal__close {
  cursor: pointer;
  background-color: #fff;
  background-image: url(/ResourcePackages/Corporate/assets/src/images/locations/icon-close-01.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 16px;
  border: 0;
  display: block;
  height: 1.375em;
  margin-right: 0.75em;
  margin-top: 0.75em;
  padding: 5px;
  right: 12px;
  width: 1.375em;
  z-index: 999;
}

.s-modal__title {
  font-size: 26px;
  color: #0c141b;
  font-weight: 300;
  text-align: center;
  border-bottom: 1px solid #e3e7ea;
  box-shadow: inset 0 -8px 7px 0 rgba(227, 231, 234, 0.5);
  margin: 0;
  padding: 0.4em 0.3em 1em;
}

@media only screen and (min-width: 544px) {
  .s-modal__title {
    font-size: calc(26px + (1400vw - 7616px) / 656);
  }
}
@media only screen and (min-width: 1200px) {
  .s-modal__title {
    font-size: 40px;
  }
}
.s-modal__content {
  padding: 1em;
}

.s-modal__subtitle {
  font-size: 17px;
  color: #58595c;
  font-weight: 700;
  text-align: center;
}

@media only screen and (min-width: 544px) {
  .s-modal__subtitle {
    font-size: calc(17px + (500vw - 2720px) / 656);
  }
}
@media only screen and (min-width: 1200px) {
  .s-modal__subtitle {
    font-size: 22px;
  }
}
.s-modal__list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
}

.s-modal__list button {
  color: #266dce;
  cursor: pointer;
  font-family: NexaLight, Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif;
  font-size: 1.125em;
  text-align: left;
  background-color: transparent;
  background-image: url(/ResourcePackages/Corporate/assets/src/images/icon-arrow.png);
  background-position: center right 5px;
  background-repeat: no-repeat;
  border: 0;
  display: block;
  padding-bottom: 1em;
  padding-top: 1em;
  width: 100%;
  transition: background 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.s-modal__list button:hover {
  background-color: rgba(234, 235, 237, 0.388);
}

@media (min-width: 34em) {
  .s-modal__list button {
    font-size: 1.25em;
  }
}
@media (min-width: 48em) {
  .s-modal__list button {
    font-size: 1.375em;
  }
}
.s-modal__list li {
  border-bottom: 1px solid #e3e7ea;
  flex: 0 0 100%;
}

@media (min-width: 48em) {
  .s-modal__list li {
    flex: 0 0 calc(50% - 30px);
    margin-left: 15px;
    margin-right: 15px;
  }
}
.s-modal iframe {
  border: 0;
}

.box__cta {
  display: flex;
}

.box__cta img {
  max-width: 66px;
  width: 66px;
}

.box__cta button {
  cursor: pointer;
  text-align: left;
  background: transparent;
  border: 0;
  display: block;
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 30em) and (max-width: 48em) {
  .box__cta {
    align-items: center;
    flex-direction: column;
  }
}
.box__txt {
  margin-left: 1.375em;
}

@media (min-width: 30em) and (max-width: 48em) {
  .box__txt {
    margin-right: 1.375em;
    margin-top: 10px;
  }
}
button.btn {
  font-family: inherit;
  border: 0;
}